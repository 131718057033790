import ApiResponse from "../models/apiResponse";
import axios from "axios";
import Util from "./util";
import WorkspaceProperties from "../models/workspaceProperties";

export default {
  async all(params: any): Promise<ApiResponse> {
    const url = Util.apiUrl("/api/v1/workspaces");

    return await axios.get<ApiResponse>(url, {
      headers: Util.authHeaders(),
      params: params,
    });
  },
  async allPosts(slug: string, params: QueryContext): Promise<ApiResponse> {
    const url = Util.apiUrl(`/api/v1/workspaces/${slug}/posts`);

    return await axios.get<ApiResponse>(url, {
      headers: Util.authHeaders(),
      params: params,
    });
  },
  async allRoutines(slug: string, params: QueryContext): Promise<ApiResponse> {
    const url = Util.apiUrl(`/api/v1/workspaces/${slug}/routines`);

    return await axios.get<ApiResponse>(url, {
      headers: Util.authHeaders(),
      params: params,
    });
  },
  async allUsers(slug: string, params: QueryContext): Promise<ApiResponse> {
    const url = Util.apiUrl(`/api/v1/workspaces/${slug}/users`);

    return await axios.get<ApiResponse>(url, {
      headers: Util.authHeaders(),
      params: params,
    });
  },
  async getBySlug(slug: string): Promise<ApiResponse> {
    const url = Util.apiUrl(`/api/v1/workspaces/${slug}`);

    return await axios.get<ApiResponse>(url, {
      headers: Util.authHeaders(),
    });
  },
  async update(
    slug: string,
    properties: WorkspaceProperties,
  ): Promise<ApiResponse> {
    const url = Util.apiUrl(`/api/v1/workspaces/${slug}`);

    return await axios.put<ApiResponse>(url, properties, {
      headers: Util.authHeaders(),
    });
  },
  async updateAvatar(slug: string, file: File): Promise<ApiResponse> {
    const url = Util.apiUrl(`/api/v1/workspaces/${slug}/avatar`);
    const params = new FormData();
    params.append("avatar", file);

    return await axios.put<ApiResponse>(url, params, {
      headers: Util.authHeaders(),
    });
  },
};
