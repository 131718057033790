<script setup lang="ts">
import { DateTime } from "luxon";
import Post from "../models/post";
import { useAuth } from "@/stores/auth";
import VAvatar from "./VAvatar.vue";

const authStore = useAuth();
const props = defineProps<{
  post: Post;
}>();

function datetimeString(datetime: string): string {
  return DateTime.fromISO(datetime, { zone: "utc" })
    .toLocal()
    .toLocaleString(DateTime.DATETIME_MED);
}
</script>

<template>
  <div class="post-card">
    <div class="avatar-column mr-1">
      <div class="author mt-1">
        <VAvatar :src="post.user.avatar.thumbnailUrl" :text="post.user.name" />
      </div>
    </div>
    <div class="content-column">
      <div>
        <strong>{{ post.user.name }}</strong>
        <span class="grey--text"> • </span>
        <span class="grey--text">
          {{ datetimeString(post.timestamp) }}
        </span>
        <br />
        <strong>{{ post.routine.name }}</strong>
      </div>
      <span class="post">{{ post.text }}</span>
    </div>
  </div>
</template>

<style lang="scss">
.post {
  white-space: pre-line;
}

.post-card {
  display: flex;
  padding: 16px 0;

  &:hover {
    background: rgb(250, 250, 250);
  }
}

.post-card:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.avatar-column {
  flex: 0 0 44px;
  padding-left: 16px;
}

.content-column {
  flex: 1;
}

.post-card-menu > span {
  cursor: pointer;
  user-select: none;
}
</style>
