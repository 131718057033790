<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";
import VLinearLoading from "../components/VLinearLoading.vue";

export interface Props {
  loading?: boolean;
  to?: string;
  type?: string;
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  type: "normal",
});

const router = useRouter();

const genClass: string = computed(() => {
  return ["button", props.type].join(" ");
});

function handleClick() {
  if (props.to) {
    router.push(props.to);
  }
}
</script>

<template>
  <div class="button" @click="handleClick()">
    <slot v-if="!loading"></slot>
    <VLinearLoading v-if="loading" />
  </div>
</template>

<style lang="scss" scoped>
.button {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  font-weight: 600;
  justify-content: center;
  min-width: 78px;
  padding: 0.8em 1em;
}

.normal {
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.text {
  background-color: clear;
}
</style>
