<script setup lang="ts">
import { computed } from "vue";

export interface Props {
  left?: boolean;
  icon: string;
  right?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  left: false,
  icon: "add",
  right: false,
});

const genClass = computed(() => {
  return [
    "material-icons",
    props.left ? "left" : "",
    props.right ? "right" : "",
  ].join(" ");
});
</script>

<template>
  <span :class="genClass" v-text="icon"></span>
</template>

<style lang="scss" scoped>
.left {
  margin-right: 0.3em;
}

.right {
  margin-left: 0.3em;
}
</style>
