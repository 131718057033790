<script setup lang="ts">
import VAvatar from "../components/VAvatar.vue";

const props = defineProps<{
  workspaces: Workspace[];
}>();

const emit = defineEmits<{
  (e: "change", slug: string): void;
}>();
</script>

<template>
  <div class="workspace-card">
    <ul class="workspace-list">
      <li
        v-for="(item, i) in workspaces"
        :key="i"
        class="workspace-entry"
        @click="emit('change', item.slug)"
      >
        <div class="list-item">
          <VAvatar
            :src="item.avatar.thumbnailUrl"
            :text="item.name"
            class="list-item"
          />
          <span class="ml-1 mt-0">{{ item.name }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
.workspace-card {
  background: white;
  padding: 0.5em 1em;
}

.workspace-list {
  display: block;
  padding: 0;
}

.workspace-entry {
  display: flex;
  padding: 1em;
}

.workspace-entry:hover {
  background-color: #f2f2f2;
}

.list-item {
  float: left;
}
</style>
