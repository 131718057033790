export default class RoutineProperties {
  cycleDays: number;
  description?: string;
  isArchived: boolean;
  name?: string;
  workspaceId?: number;
  url?: string;

  constructor(routine: Routine) {
    this.cycleDays = routine.cycleDays;
    this.description = routine.description;
    this.isArchived = routine.isArchived;
    this.name = routine.name;
    this.workspaceId = routine.workspaceId;
    this.url = routine.url;
  }
}
