<script setup lang="ts">
import { ref } from "vue";

const emit = defineEmits(["delete-post", "edit-post"]);

const showMenu = ref(false);

function toggleMenu() {
  showMenu.value = !showMenu.value;
}

function deleteClicked() {
  toggleMenu();
  emit("delete-post");
}

function editClicked() {
  toggleMenu();
  emit("edit-post");
}
</script>

<template>
  <div class="dropdown">
    <button @click="toggleMenu" class="dropbtn">⋮</button>
    <div class="dropdown-content" :class="{ show: showMenu }">
      <a href="#" @click="editClicked">Edit</a>
      <a href="#" @click="deleteClicked">Delete</a>
    </div>
  </div>
</template>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: white;
  color: black;
  padding: 5px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}
</style>
