<template>
  <transition>
    <div v-if="modelValue" class="modal" @click="handleClick()">
      <div class="modal-content" @click.stop="nothing()">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
}>();

function handleClick() {
  emit("update:modelValue", false);
}

function nothing() {
  // Empty
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
}

.modal-content {
  margin: 0 auto;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
