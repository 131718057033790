export default class PostProperties {
  routineId: number;
  text: string;
  timestamp: string;

  constructor(routineId: number, text: string, timestamp: string) {
    this.routineId = routineId;
    this.text = text;
    this.timestamp = timestamp;
  }
}
