import App from "./App.vue";
import { createApp } from "vue";
import { createPinia } from "pinia";
import router from "./router";
import VueCookies from "vue-cookies";
import * as Sentry from "@sentry/vue";

import "./scss/main.scss";

const app = createApp(App).use(createPinia()).use(VueCookies);
const env = import.meta.env.VITE_RELEASE_ENV;
const version = import.meta.env.PACKAGE_VERSION;

/* c8 ignore start */
if (["production", "staging"].includes(env)) {
  Sentry.init({
    app,
    dsn: "https://0579f3db3fac43fea7e4a0bea67e49c2@o235257.ingest.sentry.io/4504767132205056",
    environment: env,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    release: `routinely-portal@${version}`,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: [
      "api.routinely.codedge.dev",
      "api.routinely.codedge.io",
      "api.warden.codedge.dev",
      "api.warden.codedge.io",
      "routinely.codedge.dev",
      "routinely.codedge.io",
      "warden.codedge.dev",
      "warden.codedge.io",
      /^\//,
    ],
    tracesSampleRate: 1.0,
  });
}
/* c8 ignore stop */

app.use(router);
app.mount("#app");

export { app };
