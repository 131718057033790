<script lang="ts" setup>
import { ref } from "vue";
import { useAuth } from "@/stores/auth";
import { useWorkspace } from "@/stores/workspace";
import { useRoute, useRouter } from "vue-router";
import logoUrl from "../assets/routinely-logo-white.png";

import AppSplash from "../components/AppSplash.vue";
import VButton from "../components/VButton.vue";
import VLinearLoading from "../components/VLinearLoading.vue";
import VIcon from "../components/VIcon.vue";

const authStore = useAuth();
const workspaceStore = useWorkspace();
const router = useRouter();

const email = ref("");
const password = ref("");

function doSuccessfulRedirect() {
  if (authStore.activeWorkspace) {
    router.push(`/${authStore.activeWorkspace}/home`);
  } else {
    router.push("/");
  }
}

async function requestLogin() {
  try {
    await authStore.login(email.value, password.value);
    await workspaceStore.fetchAll();
    doSuccessfulRedirect();
  } catch {
    // Nothing
  }
}
</script>

<template>
  <AppSplash>
    <form class="login-form" data-form-type="login" autocomplete="on">
      <img height="100" :src="logoUrl" class="logo" alt="Routinely logo" />
      <p
        v-if="authStore.hasError"
        class="red--text banner"
        v-text="authStore.errorMessage"
      />
      <input
        v-model="email"
        type="email"
        autocomplete="username"
        class="text-input"
        placeholder="E-mail"
      />
      <input
        v-model="password"
        class="text-input"
        type="password"
        autocomplete="current-password"
        placeholder="Password"
        v-on:keyup.enter="requestLogin"
      />
      <VButton class="purple" @click="requestLogin">
        <VIcon
          v-if="!authStore.isLoading"
          class="white--text"
          icon="lock"
          left
        />
        <span v-if="!authStore.isLoading" class="white--text">Log In</span>
        <VLinearLoading v-if="authStore.isLoading" />
      </VButton>
      <VButton class="white--text" type="text" to="/forgot-password">
        <span class="white--text">Forgot password?</span>
      </VButton>
      <span class="white--text mt-3">
        Don't have an account?
        <router-link to="/signup">Sign up.</router-link>
      </span>
    </form>
  </AppSplash>
</template>

<style lang="scss" scoped>
.logo {
  margin: 0 auto 1em auto;
}

.login-form {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 3em;
  min-height: 100vh;
  position: relative;
}

.heading-text {
  margin-bottom: 0;
}

.text-input {
  border-radius: 4px;
  padding: 0.9em;
  margin-bottom: 0.9em;
}

.banner {
  padding: 0.3em;
}
</style>
