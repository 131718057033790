<script setup lang="ts">
import { computed, ref } from "vue";
import VIcon from "./VIcon.vue";

const props = defineProps<{
  avatarUrl?: string;
}>();

const emit = defineEmits<{
  (e: "change", file: File): void;
}>();

defineExpose({
  reset,
});

const input = ref(null);
const selectedFile: File = ref(null);

const previewUrl = computed(() => {
  return !!selectedFile.value
    ? URL.createObjectURL(selectedFile.value)
    : props.avatarUrl;
});

function didSelectFile(e: any) {
  selectedFile.value = e.target.files[0];
  emit("change", selectedFile.value);
}

function selectFile() {
  input.value.click();
}

function reset() {
  selectedFile.value = null;
}
</script>

<template>
  <div class="avatar-upload-container" @click="selectFile()">
    <img
      v-if="previewUrl"
      :src="previewUrl"
      alt="Avatar"
      class="current-avatar"
      width="280"
      height="280"
    />
    <div class="avatar-overlay">
      <VIcon class="upload-icon text--white large-font" icon="add_a_photo" />
    </div>
    <input
      ref="input"
      class="avatar-input"
      type="file"
      @change="didSelectFile"
    />
  </div>
</template>

<style lang="scss">
.avatar-upload-container {
  border-radius: 4px;
  height: 280px;
  width: 280px;
  position: relative;
}

.avatar-input {
  border-radius: 4px;
  display: none;
  height: 280px;
  width: 280px;
  z-index: 4;
  position: absolute;
}

.avatar-overlay {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.4);
  height: 280px;
  width: 280px;
  z-index: 3;
  position: absolute;
}

.upload-icon {
  color: white;
  position: absolute;
  left: 43%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.current-avatar {
  border-radius: 4px;
  z-index: 1;
  position: absolute;
}
</style>
