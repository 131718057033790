<script setup lang="ts">
import AppSplash from "../components/AppSplash.vue";
import { computed } from "vue";
import { useAuth } from "@/stores/auth";
import { useWorkspace } from "@/stores/workspace";
import VButton from "../components/VButton.vue";
import wardenLogoUrl from "../assets/routinely-logo-white.png";

const props = defineProps<{
  slug?: string;
}>();

const authStore = useAuth();
const workspaceStore = useWorkspace();

const homePath = computed(() => {
  return authStore.activeWorkspace
    ? `/${authStore.activeWorkspace}/routines`
    : "/login";
});
</script>

<template>
  <AppSplash>
    <div class="panel">
      <img height="100" :src="wardenLogoUrl" class="logo" alt="Warden logo" />
      <h1 class="heading-text white--text">Oops...</h1>
      <p class="white--text">
        Looks like you tried to go somewhere that doesn't exist. Sorry about
        that. Let's get you back on track.
      </p>
      <VButton class="purple" :to="homePath">
        <span class="white--text">Back to Home</span>
      </VButton>
    </div>
  </AppSplash>
</template>

<style lang="scss" scoped>
.logo {
  margin: 0 auto;
}

.panel {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 3em;
  min-height: 100vh;
  position: relative;
}

.heading-text {
  margin-bottom: 0;
}
</style>
