<script setup lang="ts">
import AvatarPicker from "../components/AvatarPicker.vue";
import { computed, onMounted, ref } from "vue";
import ProfileProperties from "../models/profileProperties";
import { useAuth } from "@/stores/auth";
import { useToast } from "vue-toast-notification";
import VButton from "../components/VButton.vue";
import VTextField from "../components/VTextField.vue";
import "vue-toast-notification/dist/theme-default.css";

const authStore = useAuth();
const name = ref("");
const toast = useToast();

const newAvatar: File = ref(null);
const avatarPicker = ref(null);

const hasNewAvatar: boolean = computed(() => {
  return !!newAvatar.value;
});

onMounted(async () => {
  await authStore.fetchProfile();
  let profile = await authStore.getProfile();
  name.value = profile.name;
});

async function onSave() {
  let properties = new ProfileProperties();
  properties.name = name.value;

  await authStore.updateProfile(properties);
  toast.success("Profile updated.", { position: "bottom" });
}

async function onSaveAvatar() {
  await authStore.updateProfileAvatar(newAvatar.value);
  resetAvatar();
  toast.success("Avatar updated.", { position: "bottom" });
}

function resetAvatar() {
  newAvatar.value = null;
  avatarPicker.value.reset();
}

function didSelectFile(file: File) {
  newAvatar.value = file;
}
</script>

<template>
  <div class="page">
    <div class="heading">
      <h1 class="inline">Account Settings</h1>
    </div>
    <div class="settings-content">
      <div>
        <AvatarPicker
          :avatar-url="authStore.avatarUrl"
          ref="avatarPicker"
          @change="didSelectFile"
        />
        <VButton
          v-if="hasNewAvatar"
          class="purple white--text mt-3 mr-1"
          @click="onSaveAvatar()"
        >
          <span>Save</span>
        </VButton>
        <VButton
          v-if="hasNewAvatar"
          class="grey white--text mt-3"
          @click="resetAvatar()"
        >
          <span>Reset</span>
        </VButton>
      </div>
      <div>
        <VTextField
          v-model="authStore.profile.email"
          label="E-mail"
          placeholder="E-mail"
          class="mb-1 mt-1"
          disabled
        />
        <VTextField
          v-model="name"
          label="Name"
          placeholder="Name"
          class="mb-1 mt-1"
        />
        <VButton class="purple white--text mt-3" @click="onSave()">
          <span>Save</span>
        </VButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.settings-content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 0px;
}
</style>
