<script setup="setup" lang="ts">
import { computed, useSlots } from "vue";

export interface Props {
  description?: string;
  headers?: string[];
  items?: any[];
  limit?: number;
  noDataText?: string;
  offset?: number;
  rowsPerPageOptions?: number[];
  sortBy?: string[];
  sortDir?: string[];
  total?: number;
}

const props = withDefaults(defineProps<Props>(), {
  description: "Table",
  limit: 10,
  noDataText: "Nothing here.",
  offset: 0,
  rowsPerPageOptions: [10, 25, 50, 100],
  sortBy: [],
  sortDir: [],
  total: 0,
});

const slots = useSlots();

const emit = defineEmits<{
  (e: "select-row", item: any): void;
  (e: "update:limit", value: number): void;
  (e: "update:offset", value: number): void;
  (e: "update:sortBy", value: string[]): void;
  (e: "update:sortDir", value: string[]): void;
}>();

const currentPage: number = computed(() => {
  return Math.ceil(props.offset / props.limit) + 1;
});

const totalPages: number = computed(() => {
  if (props.total === 0) {
    return 1;
  }
  return Math.ceil(props.total / props.limit);
});

const isFirstPage: boolean = computed(() => {
  return currentPage === 1;
});

const isLastPage: boolean = computed(() => {
  return currentPage === totalPages;
});

function clickSort(name) {
  let index = props.sortBy.indexOf(name);
  if (index < 0) {
    addSort(name, "asc");
    return;
  }

  let dir = props.sortDir[index];
  dir === "asc" ? setSort(name, "desc") : removeSort(name);
}

function addSort(name, dir) {
  if (props.sortBy.includes(name)) {
    return;
  }

  let sortByCopy = [...props.sortBy];
  let sortDirCopy = [...props.sortDir];

  sortByCopy.push(name);
  sortDirCopy.push(dir);

  emit("update:sortBy", sortByCopy);
  emit("update:sortDir", sortDirCopy);
}

function setSort(name, dir) {
  let index = props.sortBy.indexOf(name);
  if (index < 0) {
    return;
  }

  let sortDirCopy = [...props.sortDir];
  sortDirCopy[index] = dir;

  emit("update:sortDir", sortDirCopy);
}

function removeSort(name) {
  let index = props.sortBy.indexOf(name);
  if (index < 0) {
    return;
  }

  let sortByCopy = [...props.sortBy];
  let sortDirCopy = [...props.sortDir];

  sortByCopy.splice(index);
  sortDirCopy.splice(index);

  emit("update:sortBy", sortByCopy);
  emit("update:sortDir", sortDirCopy);
}

function isSorted(name) {
  return props.sortBy.includes(name);
}

function sortIcon(name) {
  let index = props.sortBy.indexOf(name);
  if (index < 0) {
    return null;
  }

  return props.sortDir[index] === "asc" ? "arrow_upward" : "arrow_downward";
}

function handlePageSizeChange(size) {
  emit("update:offset", 0);
  emit("update:limit", Number(size));
}

function isDefined(name) {
  return slots && slots[`item.${name}`];
}

function nextPage() {
  if (currentPage.value < totalPages.value) {
    emit("update:offset", props.offset + props.limit);
  }
}

function previousPage() {
  if (currentPage.value > 1) {
    emit("update:offset", props.offset - props.limit);
  }
}

function selectRow(item: any) {
  emit("select-row", item);
}
</script>

<template>
  <div class="table">
    <table :aria-label="description">
      <thead>
        <tr>
          <th v-for="(header, i) in headers" :key="i">
            <span
              v-if="header.sortable == undefined || header.sortable"
              v-text="header.text"
              @click="clickSort(header.value)"
            />
            <span v-else v-text="header.text" />
            <span
              v-if="isSorted(header.value)"
              class="material-icons sort-icon"
              v-text="sortIcon(header.value)"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="items.length === 0">
          <td :colspan="headers.length">
            <p class="no-data grey--text" v-text="noDataText" />
          </td>
        </tr>
        <tr v-else v-for="(item, i) in items" :key="i" @click="selectRow(item)">
          <td v-for="(header, j) in headers" :key="j">
            <slot :name="`item.${header.value}`" :item="item" />
            <span v-if="!isDefined(header.value)">
              {{ item[header.value] }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="table-pagination-nav">
      <div class="data-table-select">
        <label class="row-selection-label" for="num-select">
          Rows per page:
        </label>
        <div class="styled-select">
          <select
            id="num-select"
            :value="limit"
            v-on:change="handlePageSizeChange($event.target.value)"
          >
            <option
              class="pageSizeOption"
              v-for="option in rowsPerPageOptions"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
      </div>
      <div class="v-data-footer__pagination page-information">
        {{ currentPage }} of {{ totalPages }}
      </div>
      <div class="v-data-footer__icons-before">
        <button
          type="button"
          class="pagination-button v-btn v-btn--disabled v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
          aria-label="Previous page"
          :disabled="isFirstPage"
          @click="previousPage()"
        >
          <span class="v-btn__content">
            <i
              aria-hidden="true"
              class="navigation_icon v-icon notranslate material-icons theme--light"
            >
              chevron_left
            </i>
          </span>
        </button>
      </div>
      <div class="v-data-footer__icons-after">
        <button
          type="button"
          class="pagination-button v-btn v-btn--disabled v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
          aria-label="Next page"
          :disabled="isLastPage"
          @click="nextPage()"
        >
          <span class="v-btn__content">
            <i
              aria-hidden="true"
              class="navigation_icon v-icon notranslate material-icons theme--light"
            >
              chevron_right
            </i>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

tbody {
  tr:hover {
    background: #fafafa;
  }
}

.sort-icon {
  display: inline-flex;
  font-size: 16px;
  vertical-align: middle;
}

.no-data {
  text-align: center;
  width: 100%;
}

td,
th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-bottom-style: solid;
  border-bottom-width: thin;
  height: 48px;
  padding: 0 0.5em;
}

th {
  text-align: left;
  font-size: 0.8rem;
}
</style>
