<script setup="setup" lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import RecentPostCard from "../components/RecentPostCard.vue";
import StatCard from "../components/StatCard.vue";
import { sanitizeUrl } from "@braintree/sanitize-url";
import QueryContext from "../models/queryContext";
import { snakecase } from "stringcase";
import { useAuth } from "@/stores/auth";
import { useWorkspace } from "@/stores/workspace";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import WorkspaceApi from "../api/workspace";

const props = defineProps<{
  slug: string;
}>();

const authStore = useAuth();
const workspaceStore = useWorkspace();
const router = useRouter();
const toast = useToast();

const limit = 10;
const sortBy = ["timestamp"];
const sortDir = ["desc"];

const offset = ref(0);
const total = ref(0);
const posts = ref([]);

const okTotal = ref(0);
const dueSoonTotal = ref(0);
const overdueTotal = ref(0);
const notCheckedTotal = ref(0);
const allTotal = ref(0);

const totalContext = {
  overdue: { limit: 20, offset: 0, daysRemainingLt: 0 },
  dueSoon: { limit: 20, offset: 0, daysRemainingLt: 14, daysRemainingGt: -1 },
  ok: { limit: 20, offset: 0, daysRemainingGt: 13 },
  notChecked: { limit: 20, offset: 0, isChecked: false },
};

onMounted(() => {
  fetchRecentPosts();
  fetchTotals(props.slug);
});

const activeWorkspaceId = computed(() => {
  let org = workspaceStore.getWorkspaceBySlug(props.slug);
  return !!org && !!org.id ? org.id : "";
});

watch(
  () => authStore.activeWorkspace,
  (newValue, _oldValue) => {
    if (!newValue) {
      return;
    }

    fetchRecentPostsForWorkspace(newValue);
    fetchTotals(newValue);
  },
);

function fetchRecentPosts() {
  fetchRecentPostsForWorkspace(props.slug);
}

function routineDetailUrl(routine) {
  return sanitizeUrl("/" + props.slug + "/routines/" + routine.id);
}

function openRoutineDetail(post: Post) {
  const url = routineDetailUrl(post.routine);
  router.push(url);
}

async function fetchRecentPostsForWorkspace(slug: string) {
  const context = new QueryContext(
    limit,
    offset.value,
    sortBy.map(snakecase),
    sortDir,
    "",
  );
  let response = await WorkspaceApi.allPosts(slug, context);
  posts.value = response.data.posts;
  total.value = response.data.pagination.total;
}

async function fetchTotals(slug: string) {
  try {
    const results = await Promise.all([
      WorkspaceApi.allRoutines(slug, totalContext.ok),
      WorkspaceApi.allRoutines(slug, totalContext.dueSoon),
      WorkspaceApi.allRoutines(slug, totalContext.overdue),
      WorkspaceApi.allRoutines(slug, totalContext.notChecked),
    ]);

    const totals = results.map((x) => x.data.pagination.total);

    okTotal.value = totals[0];
    dueSoonTotal.value = totals[1];
    overdueTotal.value = totals[2];
    notCheckedTotal.value = totals[3];
    allTotal.value = totals.reduce((a, b) => a + b, 0);
  } catch {
    // Nothing
  }
}
</script>

<template>
  <div class="page">
    <div class="stat-card-group">
      <StatCard :header="overdueTotal" detail="Overdue" class="red--text" />
      <StatCard :header="dueSoonTotal" detail="Due Soon" class="orange--text" />
      <StatCard
        :header="okTotal"
        detail="Recently Checked"
        class="green--text"
      />
      <StatCard
        v-if="notCheckedTotal > 0"
        :header="notCheckedTotal"
        detail="Never Checked"
        class="grey--text"
      />
      <StatCard :header="allTotal" detail="Total" class="purple--text" />
    </div>
    <div class="heading">
      <h1 class="inline">Recent Activity</h1>
    </div>
    <RecentPostCard
      v-for="(post, i) in posts"
      :key="i"
      :post="post"
      @edit-post="openUpdatePostDialog(post)"
      @click="openRoutineDetail(post)"
    />
  </div>
</template>

<style lang="scss" scoped>
.inline {
  display: inline-block;
}

.heading {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.stat-card-group {
  display: flex;
}
</style>
