import ApiResponse from "../models/apiResponse";
import { defineStore } from "pinia";
import type Workspace from "../models/workspace";
import WorkspaceApi from "../api/workspace";

export const useWorkspace = defineStore("workspace", {
  state: () => {
    return {
      isLoading: false,
      errorMessage: null as string | null,
      workspaceSlugs: [] as string[],
      workspaceMap: new Map<string, Workspace>(),
      total: 0,
    };
  },
  getters: {
    getWorkspaceBySlug: (state) => {
      return (slug: string) => state.workspaceMap.get(slug);
    },
    hasMultipleWorkspaces(): boolean {
      return this.workspaceSlugs.length > 1;
    },
    workspaces(): Workspace[] {
      return this.workspaceSlugs.map((slug: string) => {
        return this.workspaceMap.get(slug) || new Workspace();
      });
    },
  },
  actions: {
    async fetchAll(): Promise<ApiResponse> {
      this.isLoading = true;

      try {
        let response = await WorkspaceApi.all({
          sortBy: "name",
          sortDir: "asc",
        });

        const data = response.data.workspaces as Workspace[];

        this.workspaceSlugs = data.map((n: Workspace) => n.slug);
        this.workspaceMap = data.reduce(
          (acc: Map<string, Workspace>, n: Workspace) => {
            if (!n.slug) {
              return acc;
            }
            acc.set(n.slug, n);
            return acc;
          },
          new Map<string, Workspace>(),
        );
      } catch (error) {
        // Nothing yet.
      } finally {
        this.isLoading = false;
      }
    },
    async fetchBySlug(slug: string): Promise<ApiResponse> {
      this.isLoading = true;

      try {
        let response = await WorkspaceApi.getBySlug(slug);
        this.workspaceMap.set(slug, response.data.workspace as Workspace);
      } catch (error) {
        // Nothing yet.
      } finally {
        this.isLoading = false;
      }
    },
    async update(
      slug: string,
      properties: ProfileProperties,
    ): Promise<ApiResponse> {
      this.isLoading = true;

      try {
        let response = await WorkspaceApi.update(slug, properties);
        this.workspaceMap.set(slug, response.data.workspace as Workspace);
      } catch (error) {
        // Nothing yet.
      } finally {
        this.isLoading = false;
      }
    },
    async updateAvatar(slug: string, file: File): Promise<ApiResponse> {
      this.isLoading = true;

      try {
        let response = await WorkspaceApi.updateAvatar(slug, file);
        this.workspaceMap.set(slug, response.data.workspace as Workspace);
      } catch (error) {
        // Nothing yet.
      } finally {
        this.isLoading = false;
      }
    },
  },
});
