<script setup lang="ts">
import { DateTime } from "luxon";
import PostApi from "../api/post";
import PostProperties from "../models/postProperties";
import { ref } from "vue";
import VButton from "../components/VButton.vue";
import VTextArea from "../components/VTextArea.vue";

const props = defineProps<{
  routineId: string;
}>();

const text = ref();

const emit = defineEmits<{
  (e: "save", properties: PostProperties): void;
}>();

async function onSave() {
  const properties = new PostProperties(
    props.routineId,
    text.value,
    DateTime.utc().toISO(),
  );
  await PostApi.create(properties);
  emit("save", properties);
}
</script>

<template>
  <div class="card new-post">
    <h3 class="inline">Record an entry</h3>
    <VTextArea
      v-model="text"
      :rows="5"
      placeholder="Write something..."
      class="mb-3"
    />
    <VButton class="purple white--text" @click="onSave()">
      <span>Submit</span>
    </VButton>
  </div>
</template>

<style lang="scss" scoped>
.inline {
  display: inline-block;
}

.heading {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.post {
  white-space: pre-line;
}

.post-card {
  padding: 16px 0;
}

.post-card:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.avatar {
  border-radius: 4px;
  padding: 8px;
}

.link {
  display: inline-flex;
}

.new-post {
  min-width: 600px;
}
</style>
