import { app } from "../main";

export default class Util {
  static authHeaders(): Record<string, any> {
    return { Authorization: `Bearer ${app.$cookies.get("token")}` };
  }

  static apiUrl(path: string): string {
    return `${import.meta.env.VITE_API_URL}${path}`;
  }

  static getToken(): string {
    return app.$cookies.get("token");
  }

  static saveToken(token: string) {
    app.$cookies.set("token", token, "1d", "/", import.meta.env.VITE_DOMAIN);
  }

  static removeToken() {
    app.$cookies.remove("token", "/", import.meta.env.VITE_DOMAIN);
  }
}
