<script setup lang="ts">
import AvatarPicker from "../components/AvatarPicker.vue";
import { computed, onMounted, ref } from "vue";
import { useToast } from "vue-toast-notification";
import { useWorkspace } from "@/stores/workspace";
import VButton from "../components/VButton.vue";
import VTextField from "../components/VTextField.vue";
import "vue-toast-notification/dist/theme-default.css";
import WorkspaceProperties from "../models/workspaceProperties";

const props = defineProps<{
  slug: string;
}>();

const workspaceStore = useWorkspace();
const toast = useToast();

const avatarUrl = ref("");
const name = ref("");
const newAvatar: File = ref(null);
const avatarPicker = ref(null);

const activeWorkspace = computed(() => {
  return workspaceStore.getWorkspaceBySlug(props.slug) ?? {};
});

const hasNewAvatar: boolean = computed(() => {
  return !!newAvatar.value;
});

onMounted(async () => {
  await workspaceStore.fetchBySlug(props.slug);
  let workspace = workspaceStore.getWorkspaceBySlug(props.slug);

  avatarUrl.value =
    !!workspace && workspace.avatar ? workspace.avatar.url : undefined;
  name.value = workspace.name;
});

async function onSave() {
  let properties = new WorkspaceProperties();
  properties.name = name.value;

  await workspaceStore.update(props.slug, properties);
  toast.success("Workspace updated.", { position: "bottom" });
}

async function onSaveAvatar() {
  await workspaceStore.updateAvatar(props.slug, newAvatar.value);
  resetAvatar();
  toast.success("Avatar updated.", { position: "bottom" });
}

function resetAvatar() {
  newAvatar.value = null;
  avatarPicker.value.reset();
}

function didSelectFile(file: File) {
  newAvatar.value = file;
}
</script>

<template>
  <div class="page">
    <div class="heading">
      <h1 class="inline">Workspace Settings</h1>
    </div>
    <div class="settings-content">
      <div>
        <AvatarPicker
          :avatar-url="activeWorkspace.avatar.url"
          ref="avatarPicker"
          @change="didSelectFile"
        />
        <VButton
          v-if="hasNewAvatar"
          class="purple white--text mt-3 mr-1"
          @click="onSaveAvatar()"
        >
          <span>Save</span>
        </VButton>
        <VButton
          v-if="hasNewAvatar"
          class="grey white--text mt-3"
          @click="resetAvatar()"
        >
          <span>Reset</span>
        </VButton>
      </div>
      <div>
        <VTextField
          v-model="props.slug"
          label="Workspace Slug"
          placeholder="not-set"
          class="mb-1 mt-1"
          disabled
        />
        <VTextField
          v-model="name"
          label="Name"
          placeholder="Name"
          class="mb-1 mt-1"
        />
        <VButton class="purple white--text mt-3" @click="onSave()">
          <span>Save</span>
        </VButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.settings-content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 0px;
}
</style>
