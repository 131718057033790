export default class Avatar {
  thumbnailUrl?: string;
  url?: string;

  static fromLocalStorageJSON(json: Record<string, string>): Avatar {
    if (json === undefined) {
      return undefined;
    }

    const avatar = new Avatar();

    avatar.thumbnailUrl = json.thumbnailUrl;
    avatar.url = json.url;

    return avatar;
  }
}
