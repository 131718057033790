<script lang="ts" setup>
import { ref } from "vue";
import { useAuth } from "@/stores/auth";
import logoUrl from "../assets/routinely-logo-white.png";

import AppSplash from "../components/AppSplash.vue";
import VButton from "../components/VButton.vue";
import VLinearLoading from "../components/VLinearLoading.vue";

const authStore = useAuth();
const email = ref("");
const success = ref(false);

async function requestReset() {
  try {
    await authStore.requestPasswordReset(email.value);
    success.value = true;
  } catch (error) {
    // Nothing
  }
}
</script>

<template>
  <AppSplash>
    <div class="login-form">
      <img height="100" :src="logoUrl" class="logo" alt="Warden logo" />
      <h1 class="heading-text white--text">Forgot password?</h1>
      <p class="white--text">Enter your e-mail address and we can reset it.</p>
      <p v-if="success" class="green white--text banner">
        Password reset requested. Please check your email.
      </p>
      <input v-model="email" class="text-input" />
      <VButton class="purple" @click="requestReset">
        <span v-if="!authStore.isLoading" class="white--text">
          Request Password Reset
        </span>
        <VLinearLoading v-if="authStore.isLoading" />
      </VButton>
      <VButton class="white--text" type="text" to="/login">
        <span class="white--text">Return to Login</span>
      </VButton>
    </div>
  </AppSplash>
</template>

<style lang="scss" scoped>
.logo {
  margin: 0 auto;
}

.login-form {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 3em;
  min-height: 100vh;
  position: relative;
}

.heading-text {
  margin-bottom: 0;
}

.text-input {
  border-radius: 4px;
  padding: 0.9em;
  margin-bottom: 0.9em;
}

.banner {
  padding: 0.5em;
}
</style>
