export default class Routine {
  createdAt?: string;
  cycleDays?: number;
  daysRemaining?: number;
  description?: string;
  id?: number;
  isArchived?: boolean;
  name?: string;
  nextWardenAt?: string;
  workspaceId?: number;
  updatedAt?: string;
  url?: string;
  wardenedAt?: string;
}
