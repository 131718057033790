import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useAuth } from "@/stores/auth";
import ChangePasswordView from "../views/ChangePasswordView.vue";
import ForgotPasswordView from "../views/ForgotPasswordView.vue";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import NotFoundView from "../views/NotFoundView.vue";
import RoutineDetailView from "../views/RoutineDetailView.vue";
import RoutinesView from "../views/RoutinesView.vue";
import SignupView from "../views/SignupView.vue";
import UserSettingsView from "../views/UserSettingsView.vue";
import UsersView from "../views/UsersView.vue";
import WorkspaceSelectView from "../views/WorkspaceSelectView.vue";
import WorkspaceSettingsView from "../views/WorkspaceSettingsView.vue";

const hasToken = () => {
  return useAuth().isAuthenticated;
};

const ifNotAuthenticated = (_to: any, _from: any, next: any) => {
  if (!hasToken()) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (_to: any, _from: any, next: any) => {
  if (hasToken()) {
    next();
    return;
  }

  next({ name: "Login" });
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Workspace Select",
    component: WorkspaceSelectView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/signup",
    name: "Signup",
    component: SignupView,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPasswordView,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/password/reset",
    name: "ChangePassword",
    component: ChangePasswordView,
    beforeEnter: ifNotAuthenticated,
    props: (route) => ({ t: route.query.t }),
  },
  {
    path: "/account",
    name: "Account Settings",
    component: UserSettingsView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/:slug/home",
    name: "Home",
    component: HomeView,
    beforeEnter: ifAuthenticated,
    props: true,
  },
  {
    path: "/:slug/routines",
    name: "Routines",
    component: RoutinesView,
    beforeEnter: ifAuthenticated,
    props: true,
  },
  {
    path: "/:slug/routines/:id",
    name: "RoutineDetail",
    component: RoutineDetailView,
    beforeEnter: ifAuthenticated,
    props: true,
  },
  {
    path: "/:slug/settings",
    name: "Workspace Settings",
    component: WorkspaceSettingsView,
    beforeEnter: ifAuthenticated,
    props: true,
  },
  {
    path: "/:slug/users",
    name: "Users",
    component: UsersView,
    beforeEnter: ifAuthenticated,
    props: true,
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFoundView,
    name: "NotFound",
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
