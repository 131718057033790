<script setup lang="ts">
import { computed, ref } from "vue";
import Routine from "../models/routine";
import RoutineApi from "../api/routine";
import RoutineProperties from "../models/routineProperties";
import VButton from "../components/VButton.vue";
import VTextArea from "../components/VTextArea.vue";
import VTextField from "../components/VTextField.vue";

export interface Props {
  workspaceId: number;
  routine: Routine;
}

const props = withDefaults(defineProps<Props>(), {
  routine: new Routine(),
});

const emit = defineEmits<{
  (e: "archive"): void;
  (e: "cancel"): void;
  (e: "delete"): void;
  (e: "save"): void;
}>();

const isEditing: boolean = computed(() => {
  return !!props.routine.id;
});

const title: string = computed(() => {
  return isEditing ? "Routine Settings" : "New Routine";
});

const properties = ref(new RoutineProperties(props.routine));

async function onArchive() {
  let properties = new RoutineProperties(props.routine);
  properties.isArchived = true;
  properties.workspaceId = props.workspaceId;
  await RoutineApi.update(props.routine.id, properties);

  emit("archive");
}

async function onDelete() {
  if (!props.routine.id) {
    return;
  }

  await RoutineApi.delete(props.routine.id);
  emit("delete");
}

async function onSave() {
  properties.value.workspaceId = props.workspaceId;
  await (isEditing.value
    ? RoutineApi.update(props.routine.id, properties.value)
    : RoutineApi.create(properties.value));

  emit("save");
}

async function onCancel() {
  properties.value = new RoutineProperties(props.routine);
  emit("cancel");
}
</script>

<template>
  <div class="card new-post">
    <h3 class="inline" v-text="title" />
    <VTextField v-model="properties.name" label="Name" />
    <VTextArea
      v-model="properties.description"
      :rows="5"
      label="Description"
      placeholder="Write something..."
      class="mb-1"
    />
    <VTextField
      v-model="properties.cycleDays"
      label="Cycle (Days)"
      type="number"
    />
    <VTextField v-model="properties.url" label="Routine URL" />
    <VButton class="purple white--text mt-3" @click="onSave()">
      <span>Save</span>
    </VButton>
    <VButton class="grey--text mt-3" type="text" @click="onCancel()">
      <span>Cancel</span>
    </VButton>
    <div v-if="isEditing" class="content-grid">
      <div>
        <h4>Archive Routine</h4>
        <p>
          Archiving a routine retains its data but removes it from warden cycle
          tracking. It can be always be unarchived.
        </p>
        <VButton class="purple white--text" @click="onArchive()">
          <span>Archive</span>
        </VButton>
      </div>
      <div>
        <h4>Delete Routine</h4>
        <p>
          Routine information and warden entries will be deleted. This data
          cannot be restored!
        </p>
        <VButton class="red white--text" @click="onDelete()">
          <span>Delete</span>
        </VButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.inline {
  display: inline-block;
}

.heading {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.post {
  white-space: pre-line;
}

.post-card {
  padding: 16px 0;
}

.post-card:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.avatar {
  border-radius: 4px;
  padding: 8px;
}

.link {
  display: inline-flex;
}

.new-post {
  width: 1000px;
}

.content-grid {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
}
</style>
