<script setup lang="ts">
import { computed, ref, watch } from "vue";

export interface Props {
  error?: string;
  label?: string;
  modelValue?: string;
  placeholder?: string;
  rows: number;
}

const props = withDefaults(defineProps<Props>(), {
  rows: 2,
});

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const text = ref(props.modelValue);

const computedClass: string = computed(() => {
  return props.error
    ? ["text-area-input", "error-border"].join(" ")
    : "text-area-input";
});

watch(
  () => props.modelValue,
  (newValue: string) => {
    text.value = newValue;
  },
);

watch(text, (newValue: string) => {
  text.value = newValue;
  emit("update:modelValue", newValue);
});
</script>

<template>
  <div class="v-text-area">
    <span v-if="label" v-text="label" />
    <textarea
      v-model="text"
      :placeholder="placeholder"
      :class="computedClass"
      :rows="rows"
    />
    <span v-text="error" class="error-text" />
  </div>
</template>

<style lang="scss" scoped>
.text-area-input {
  border: 1px solid rgb(204, 206, 209);
  border-radius: 2px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0.9em;
  width: 100%;
  margin: 0;
}

.error-border {
  border: 1px solid #ff0000;
}

.error-text {
  color: #ff0000;
}
</style>
