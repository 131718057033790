<template>
  <div class="page">
    <div class="heading">
      <h1 class="inline">Users</h1>
    </div>
    <VTextField v-model="q" placeholder="Search users..." class="mb-1 mt-1" />
    <VTable
      v-model:limit="limit"
      v-model:offset="offset"
      v-model:sort-by="sortBy"
      v-model:sort-dir="sortDir"
      v-model:total="total"
      :headers="headers"
      :items="users"
      description="Table of users"
    >
      <template #[`item.avatar`]="{ item }">
        <VAvatar :src="avatarThumbnail(item)" :text="item.name" />
      </template>
      <template #[`item.name`]="{ item }">
        <NullableField v-model="item.name" />
      </template>
      <template #[`item.role`]="{ item }">
        <VChip v-text="roleText(item.role)" />
      </template>
      <template #[`item.email`]="{ item }">
        <NullableField v-model="item.email" />
      </template>
    </VTable>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NullableField from "../components/NullableField.vue";
import WorkspaceUser from "../models/user";
import User from "../models/user";
import QueryContext from "../models/queryContext";
import { snakecase, titlecase } from "stringcase";
import VAvatar from "../components/VAvatar.vue";
import VChip from "../components/VChip.vue";
import VTable from "../components/VTable.vue";
import VTextField from "../components/VTextField.vue";
import WorkspaceApi from "../api/workspace";

export default defineComponent({
  name: "UsersView",
  components: {
    NullableField,
    VAvatar,
    VChip,
    VTable,
    VTextField,
  },
  props: ["slug"],
  mounted() {
    this.fetchAllUsers();
  },
  data() {
    return {
      debounceTimer: setTimeout(() => "", 500),
      headers: [
        { text: "", value: "avatar", sortable: false },
        { text: "Name", value: "name" },
        { text: "Role", value: "role" },
        { text: "E-mail", value: "email" },
      ],
      limit: 10,
      offset: 0,
      sortBy: [],
      sortDir: [],
      q: "",
      total: 0,
      users: [],
    };
  },
  watch: {
    limit(_newValue) {
      this.fetchAllUsers();
    },
    offset(_newValue) {
      this.fetchAllUsers();
    },
    sortBy(_newValue) {
      this.offset = 0;
      this.fetchAllUsers();
    },
    sortDir(_newValue) {
      this.offset = 0;
      this.fetchAllUsers();
    },
    q(_newValue) {
      clearTimeout(this.debounceTimer);

      this.debounceTimer = setTimeout(() => {
        this.offset = 0;
        this.fetchAllUsers();
      }, 500);
    },
  },
  methods: {
    avatarThumbnail(user) {
      return !!user.avatar ? user.avatar.thumbnailUrl : undefined;
    },
    async fetchAllUsers() {
      const context = new QueryContext(
        this.limit,
        this.offset,
        this.sortBy.map(snakecase),
        this.sortDir,
        this.q,
      );
      let response = await WorkspaceApi.allUsers(this.slug, context);
      this.users = response.data.users as WorkspaceUser[];
      this.total = response.data.pagination.total;
    },
    roleText(role) {
      return titlecase(role);
    },
  },
});
</script>
