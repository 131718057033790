<script setup lang="ts">
const props = defineProps<{
  detail: string;
  header: string | number;
}>();
</script>

<template>
  <div class="stat-card">
    <h1>{{ props.header }}</h1>
    <span>{{ props.detail }}</span>
  </div>
</template>

<style lang="scss">
.stat-card {
  margin-right: 2em;
  padding: 16px 0;
}
</style>
