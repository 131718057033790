<script lang="ts" setup>
import { ref } from "vue";
import { useAuth } from "@/stores/auth";
import { useWorkspace } from "@/stores/workspace";
import { useRouter } from "vue-router";
import logoUrl from "../assets/routinely-logo-white.png";

import AppSplash from "../components/AppSplash.vue";
import VButton from "../components/VButton.vue";
import VLinearLoading from "../components/VLinearLoading.vue";

const authStore = useAuth();
const workspaceStore = useWorkspace();
const router = useRouter();

const name = ref("");
const email = ref("");
const password = ref("");

async function requestSignup() {
  try {
    await authStore.signup(name.value, email.value, password.value);
    await workspaceStore.fetchAll();
    router.push(`/${authStore.activeWorkspace}/home`);
  } catch {
    // Nothing
  }
}
</script>

<template>
  <AppSplash>
    <form class="signup-form" data-form-type="register" autocomplete="on">
      <img height="100" :src="logoUrl" class="logo" alt="Routinely logo" />
      <h1 class="white--text">Start tracking the stuff that matters.</h1>
      <p
        v-if="authStore.hasError"
        class="red--text banner"
        v-text="authStore.errorMessage"
      />
      <span class="white--text">Your Name</span>
      <input
        v-model="name"
        type="text"
        name="name"
        autocomplete="name"
        class="text-input"
      />
      <span class="white--text">E-mail</span>
      <input
        v-model="email"
        type="email"
        name="email"
        autocomplete="username"
        class="text-input"
      />
      <span class="white--text">Password</span>
      <input
        v-model="password"
        class="text-input"
        type="password"
        name="password"
        autocomplete="current-password"
        data-form-type="password,new"
        v-on:keyup.enter="requestSignup"
      />
      <VButton class="purple mt-3" @click="requestSignup">
        <span v-if="!authStore.isLoading" class="white--text">
          Create Your Account
        </span>
        <VLinearLoading v-if="authStore.isLoading" />
      </VButton>
      <span class="white--text mt-3">
        Already have an account?
        <router-link to="/login">Log in.</router-link></span
      >
    </form>
  </AppSplash>
</template>

<style lang="scss" scoped>
.logo {
  margin: 0 auto 1em auto;
}

.signup-form {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 3em;
  min-height: 100vh;
  position: relative;
}

.heading-text {
  margin-bottom: 0;
}

.text-input {
  border-radius: 4px;
  padding: 0.9em;
  margin-bottom: 0.9em;
}

.banner {
  padding: 0.3em;
}
</style>
