<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import wardenLogoUrl from "../assets/routinely-logo-white.png";
import WorkspaceList from "../components/WorkspaceList.vue";
import User from "../models/user";
import VAvatar from "../components/VAvatar.vue";
import VButton from "../components/VButton.vue";
import VIcon from "../components/VIcon.vue";
import VModal from "../components/VModal.vue";
import VSpacer from "../components/VSpacer.vue";
import { useAuth } from "@/stores/auth";
import { useWorkspace } from "@/stores/workspace";
import { useRouter } from "vue-router";

const authStore = useAuth();
const workspaceStore = useWorkspace();
const workspaceDialogOpen = ref(false);

const props = defineProps<{
  slug: string;
}>();

const router = useRouter();

onMounted(() => {
  authStore.fetchProfile();
  workspaceStore.fetchAll();
});

const hasActiveWorkspace = computed(() => {
  return !!activeWorkspace;
});

const headerHeight = computed(() => {
  return "68px";
});

const avatarThumbnail = computed(() => {
  return !!authStore.profile && !!authStore.profile.avatar
    ? authStore.profile.avatar.thumbnailUrl
    : undefined;
});

const profileName = computed(() => {
  return !!authStore.profile && !!authStore.profile.name
    ? authStore.profile.name
    : undefined;
});

const displayWorkspaceSwitcher = computed(() => {
  return (
    !!workspaceStore.getWorkspaceBySlug(authStore.activeWorkspace) &&
    workspaceStore.hasMultipleWorkspaces
  );
});

const activeWorkspace = computed(() => {
  return workspaceStore.getWorkspaceBySlug(authStore.activeWorkspace);
});

const items = computed(() => {
  return [
    {
      name: "Home",
      path: `/${authStore.activeWorkspace}/home`,
    },
    {
      name: "Routines",
      path: `/${authStore.activeWorkspace}/routines`,
    },
    {
      name: "Users",
      path: `/${authStore.activeWorkspace}/users`,
    },
  ];
});

function changeActiveWorkspace(slug: string) {
  authStore.selectActiveWorkspace(slug);
  workspaceDialogOpen.value = false;
  router.push(`/${slug}/home`);
}

function requestLogout() {
  authStore.logout().then(() => {
    router.push("/login");
  });
}
</script>

<template>
  <nav>
    <img
      alt="Warden Logo"
      contain
      :src="wardenLogoUrl"
      transition="scale-transition"
      width="90"
    />
    <VButton
      v-for="(item, i) in items"
      :key="i"
      :to="item.path"
      variant="text"
      class="nav-item"
    >
      {{ item.name }}
    </VButton>
    <VButton
      :to="`/${authStore.activeWorkspace}/settings`"
      class="nav-item-avatar"
    >
      <VIcon icon="settings" />
    </VButton>
    <VSpacer />
    <VButton
      v-if="displayWorkspaceSwitcher"
      class="nav-item-avatar"
      @click="workspaceDialogOpen = true"
    >
      <VAvatar
        :src="activeWorkspace.avatar.thumbnailUrl"
        :text="activeWorkspace.name"
      />
    </VButton>
    <VButton to="/account" class="nav-item-avatar">
      <VAvatar :src="avatarThumbnail" :text="profileName" />
    </VButton>
    <VButton type="text" class="nav-item" @click="requestLogout">
      <span>Logout</span>
      <VIcon icon="exit_to_app" right />
    </VButton>
  </nav>
  <VModal v-model="workspaceDialogOpen">
    <WorkspaceList
      :workspaces="workspaceStore.workspaces"
      :active-slug="slug"
      @change="changeActiveWorkspace($event)"
    />
  </VModal>
</template>

<style lang="scss" scoped>
nav {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: row;
  padding: 0.8em;

  background-image: url("../assets/particles-light.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.nav-item {
  padding: 0 0.5em;
}

.nav-item-avatar {
  padding: 0;
}
</style>
