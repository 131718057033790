<template>
  <div class="page">
    <VBreadcrumbs :items="breadcrumbs" class="mt-2" />
    <div class="heading">
      <h1 class="inline">{{ routine.name }}</h1>
      <VButton class="purple--text" type="text" @click="openRoutineDialog()">
        <VIcon class="left" icon="edit" />
        <span>Edit</span>
      </VButton>
    </div>
    <div>
      <span v-if="!!routine.description">{{ routine.description }}</span>
      <br v-if="!!routine.description" />
      <div v-if="routineUrl" class="link">
        <VIcon class="left" icon="link" />
        <a :href="routineUrl">
          {{ routineUrl }}
        </a>
      </div>
    </div>
    <div class="heading">
      <h2 class="inline">Routine History</h2>
      <VSpacer />
      <VButton class="purple white--text" @click="openPostDialog">
        <VIcon class="left" icon="add" />
        <span>New Entry</span>
      </VButton>
    </div>
    <PostCard
      v-for="(post, i) in posts"
      :key="i"
      :post="post"
      @delete-post="onDeletePost"
      @edit-post="openUpdatePostDialog(post)"
    />
    <VModal v-model="routineDialogOpen">
      <RoutineForm
        :workspace-id="activeWorkspaceId"
        :routine="routine"
        @archive="onArchiveRoutine()"
        @delete="onDeleteRoutine()"
        @save="onSaveRoutine()"
        @cancel="dismissDialog()"
      />
    </VModal>
    <VModal v-model="postDialogOpen">
      <NewPostForm :routine-id="id" @save="onSavePost()" />
    </VModal>
    <VModal v-model="updatePostDialogOpen">
      <EditPostForm :post="postToBeUpdated" @update="onUpdatePost()" />
    </VModal>
  </div>
</template>

<script lang="ts">
import { DateTime } from "luxon";
import { defineComponent } from "vue";
import PostCard from "../components/PostCard.vue";
import NewPostForm from "../components/NewPostForm.vue";
import EditPostForm from "../components/EditPostForm.vue";
import NullableField from "../components/NullableField.vue";
import RoutineApi from "../api/routine";
import RoutineForm from "../components/RoutineForm.vue";
import { sanitizeUrl } from "@braintree/sanitize-url";
import User from "../models/user";
import { useWorkspace } from "@/stores/workspace";
import { useToast } from "vue-toast-notification";
import VBreadcrumbs from "../components/VBreadcrumbs.vue";
import VButton from "../components/VButton.vue";
import VIcon from "../components/VIcon.vue";
import VModal from "../components/VModal.vue";
import VSpacer from "../components/VSpacer.vue";
import VTable from "../components/VTable.vue";
import VTextArea from "../components/VTextArea.vue";
import "vue-toast-notification/dist/theme-default.css";

import PostApi from "../api/post";

export default defineComponent({
  name: "RoutineDetailView",
  components: {
    NewPostForm,
    EditPostForm,
    NullableField,
    PostCard,
    RoutineForm,
    VBreadcrumbs,
    VButton,
    VIcon,
    VModal,
    VSpacer,
    VTable,
    VTextArea,
  },
  props: ["id", "slug"],
  setup() {
    const workspaceStore = useWorkspace();
    const toast = useToast();

    return {
      workspaceStore,
      toast,
    };
  },
  mounted() {
    this.fetchRoutine();
    this.fetchPosts();
  },
  data() {
    return {
      breadcrumbs: [
        { to: `/${this.slug}/routines`, value: "Routines" },
        { value: this.id },
      ],
      limit: 10,
      offset: 0,
      postDialogOpen: false,
      updatePostDialogOpen: false,
      postToBeUpdated: { text: "" },
      routineDialogOpen: false,
      sortBy: [],
      sortDir: [],
      posts: [],
      routine: {},
      q: "",
    };
  },
  computed: {
    activeWorkspaceId() {
      let workspace = this.workspaceStore.getWorkspaceBySlug(this.slug);
      return !!workspace && !!workspace.id ? workspace.id : "";
    },
    routineUrl(): string {
      if (!this.routine || !this.routine.url) {
        return "";
      }
      return sanitizeUrl(this.routine.url);
    },
  },
  methods: {
    datetimeString(datetime: string): string {
      return DateTime.fromISO(datetime, { zone: "utc" })
        .toLocal()
        .toLocaleString(DateTime.DATETIME_MED);
    },
    dismissDialog() {
      this.routineDialogOpen = false;
    },
    async fetchRoutine() {
      let response = await RoutineApi.get(this.id);
      this.routine = response.data.routine;
    },
    async fetchPosts() {
      let response = await PostApi.all({ routineId: this.id });
      this.posts = response.data.posts;
    },
    openPostDialog() {
      this.postDialogOpen = true;
    },
    openUpdatePostDialog(post) {
      this.postToBeUpdated = post;
      this.updatePostDialogOpen = true;
    },
    openRoutineDialog() {
      this.routineDialogOpen = true;
    },
    onArchiveRoutine() {
      this.toast.success("Routine archived.", { position: "bottom" });
      this.$router.push(`/${this.slug}/routines`);
    },
    onDeleteRoutine() {
      this.toast.success("Routine deleted.", { position: "bottom" });
      this.$router.push(`/${this.slug}/routines`);
    },
    onDeletePost() {
      this.toast.success("Entry deleted", { position: "bottom" });
      this.fetchPosts();
    },
    onSavePost() {
      this.toast.success("Routine wardened.", { position: "bottom" });
      this.postDialogOpen = false;
      this.fetchPosts();
    },
    onUpdatePost() {
      this.toast.success("Routine wardened.", { position: "bottom" });
      this.updatePostDialogOpen = false;
      this.fetchPosts();
    },
    onSaveRoutine() {
      this.toast.success("Routine updated.", { position: "bottom" });
      this.routineDialogOpen = false;
      this.fetchRoutine();
    },
  },
});
</script>

<style lang="scss" scoped>
.inline {
  display: inline-block;
}

.link {
  display: inline-flex;
}

.new-post {
  min-width: 600px;
}
</style>
