import ApiResponse from "../models/apiResponse";
import axios from "axios";
import RoutineProperties from "../models/routineProperties";
import QueryContext from "../models/queryContext";
import Util from "./util";

export default {
  async all(params: QueryContext): Promise<ApiResponse> {
    const url = Util.apiUrl("/api/v1/routines");

    return await axios.get<ApiResponse>(url, {
      headers: Util.authHeaders(),
      params: params,
    });
  },
  async create(properties: RoutineProperties): Promise<ApiResponse> {
    const url = Util.apiUrl("/api/v1/routines");

    return await axios.post<ApiResponse>(url, properties, {
      headers: Util.authHeaders(),
    });
  },
  async delete(routineId: string): Promise<ApiResponse> {
    const url = Util.apiUrl(`/api/v1/routines/${routineId}`);

    return await axios.delete<ApiResponse>(url, {
      headers: Util.authHeaders(),
    });
  },
  async get(routineId: string): Promise<ApiResponse> {
    const url = Util.apiUrl(`/api/v1/routines/${routineId}`);

    return await axios.get<ApiResponse>(url, {
      headers: Util.authHeaders(),
    });
  },
  async update(
    routineId: string,
    properties: RoutineProperties,
  ): Promise<ApiResponse> {
    const url = Util.apiUrl(`/api/v1/routines/${routineId}`);

    return await axios.put<ApiResponse>(url, properties, {
      headers: Util.authHeaders(),
    });
  },
};
