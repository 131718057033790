import ApiResponse from "../models/apiResponse";
import axios from "axios";
import PostProperties from "../models/postProperties";
import Util from "./util";

export default {
  async all(params: any): Promise<ApiResponse> {
    const url = Util.apiUrl("/api/v1/posts");

    return await axios.get<ApiResponse>(url, {
      headers: Util.authHeaders(),
      params: params,
    });
  },
  async create(properties: PostProperties): Promise<ApiResponse> {
    const url = Util.apiUrl("/api/v1/posts");

    return await axios.post<ApiResponse>(url, properties, {
      headers: Util.authHeaders(),
    });
  },
  async update(
    postId: number,
    properties: PostProperties,
  ): Promise<ApiResponse> {
    const url = Util.apiUrl("/api/v1/posts/" + postId);

    return await axios.put<ApiResponse>(url, properties, {
      headers: Util.authHeaders(),
    });
  },
  async delete(postId: number): Promise<ApiResponse> {
    const url = Util.apiUrl("/api/v1/posts/" + postId);

    return await axios.delete<ApiResponse>(url, {
      headers: Util.authHeaders(),
    });
  },
};
