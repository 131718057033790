<script lang="ts" setup>
import { ref } from "vue";
import { useAuth } from "@/stores/auth";
import logoUrl from "../assets/routinely-logo-white.png";

import AppSplash from "../components/AppSplash.vue";
import VButton from "../components/VButton.vue";
import VLinearLoading from "../components/VLinearLoading.vue";

const authStore = useAuth();

const props = defineProps<{
  t: string;
}>();

const password = ref("");
success = ref(false);

async function requestReset() {
  if (!this.t) {
    return;
  }
  try {
    await authStore.resetPassword(t.value, password.value);
    success.value = true;
  } catch (_error) {
    // Nothing
  }
}
</script>

<template>
  <AppSplash>
    <div class="login-form">
      <img height="100" :src="logoUrl" class="logo" alt="Warden logo" />
      <h1 class="heading-text white--text">Change Password</h1>
      <p class="white--text">
        Enter a new, strong password of at least 8 characters.
      </p>
      <template v-if="success">
        <p class="green--text banner">Password updated. Please log in again.</p>
        <VButton v-if="success" class="purple" to="/login">
          <span class="white--text">Return to Login</span>
        </VButton>
      </template>
      <template v-else>
        <input v-model="password" class="text-input" type="password" />
        <VButton class="purple" @click="requestReset">
          <span v-if="!authStore.isLoading" class="white--text">
            Change Password
          </span>
          <VLinearLoading v-if="authStore.isLoading" />
        </VButton>
      </template>
    </div>
  </AppSplash>
</template>

<style lang="scss" scoped>
.logo {
  margin: 0 auto;
}

.login-form {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 3em;
  min-height: 100vh;
  position: relative;
}

.heading-text {
  margin-bottom: 0;
}

.text-input {
  border-radius: 4px;
  padding: 0.9em;
  margin-bottom: 0.9em;
}

.banner {
  padding: 0.5em;
}
</style>
