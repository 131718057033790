<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  src?: string;
  text?: string;
}>();

const backgroundColors = [
  "#F44336",
  "#FF4081",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#2196F3",
  "#03A9F4",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
  "#FF9800",
  "#FF5722",
  "#795548",
  "#9E9E9E",
  "#607D8B",
];

const bgStyle = computed(() => {
  if (!props.text) {
    return `background: #222;`;
  }

  let bgColor = randomBackgroundColor(props.text.length, backgroundColors);
  return `background: ${bgColor};`;
});

const initials = computed(() => {
  if (!props.text) {
    return "";
  }

  let parts = props.text.split(/[ -]/);
  let initials = "";

  for (var i = 0; i < parts.length; i++) {
    initials += parts[i].charAt(0);
  }

  if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
    initials = initials.replace(/[a-z]+/g, "");
  }

  initials = initials.substr(0, 3).toUpperCase();

  return initials;
});

function randomBackgroundColor(seed, colors) {
  return colors[seed % colors.length];
}
</script>

<template>
  <div class="avatar-container">
    <img
      v-if="src"
      :src="props.src"
      alt="User's avatar"
      class="avatar"
      height="44"
      width="44"
    />
    <div v-else :style="bgStyle" class="text-avatar">
      {{ initials }}
    </div>
  </div>
</template>

<style lang="scss">
.avatar-container {
  margin: 0;
  padding: 0;
}

.avatar {
  border-radius: 4px;
  display: block;
}

.text-avatar {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 44px;
  height: 44px;
  font-weight: 600;
}
</style>
