<template>
  <div>
    <div class="splash"></div>
    <div class="content">
      <div class="column shaded">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content {
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-height: 100vh;
  position: relative;
}

.column {
  flex: 0 0 33.3%;
  height: 100%;
}

.splash {
  position: absolute;
  background-image: url("../assets/particles-light.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.shaded {
  background-color: rgba(0, 0, 0, 0.4);
}
</style>
