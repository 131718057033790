<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useAuth } from "@/stores/auth";
import { useWorkspace } from "@/stores/workspace";
import VModal from "../components/VModal.vue";
import WorkspaceList from "../components/WorkspaceList.vue";

const authStore = useAuth();
const router = useRouter();
const workspaceDialogOpen = ref(true);
const workspaceStore = useWorkspace();

onMounted(async () => {
  await workspaceStore.fetchAll();
});

function selectWorkspace(slug) {
  authStore.selectActiveWorkspace(slug);
  router.push(`/${slug}/home`);
}
</script>

<template>
  <div class="page">
    <VModal v-model="workspaceDialogOpen">
      <WorkspaceList
        :workspaces="workspaceStore.workspaces"
        @change="selectWorkspace($event)"
      />
    </VModal>
  </div>
</template>
