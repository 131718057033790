import Avatar from "./avatar";

export default class User {
  avatar?: Avatar;
  email?: string;
  id?: number;
  name?: string;

  static fromLocalStorageJSON(json: Record<string, string>): User {
    const user = new User();

    user.avatar = Avatar.fromLocalStorageJSON(json.avatar);
    user.email = json.email;
    user.id = json.id;
    user.name = json.name;

    return user;
  }
}
