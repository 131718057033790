export default class QueryContext {
  limit = 20;
  offset = 0;
  q?: string;
  sortBy?: string[];
  sortDir?: string[];

  constructor(
    limit: number,
    offset: number,
    sortBy?: string[],
    sortDir?: string[],
    q?: string,
  ) {
    this.limit = limit;
    this.offset = offset;

    if (q && q != "") {
      this.q = q;
    }

    if (sortBy && sortBy.length > 0) {
      this.sortBy = sortBy;
    }

    if (sortDir && sortDir.length > 0) {
      this.sortDir = sortDir;
    }
  }
}
