<template>
  <AppHeader
    v-if="showRoutes.indexOf($route.name) > -1"
    :slug="$route.params.slug"
  />
  <div class="content">
    <router-view />
  </div>
  <AppFooter v-if="showRoutes.indexOf($route.name) > -1" :version="version" />
</template>

<script lang="ts">
import AppFooter from "./components/AppFooter.vue";
import AppHeader from "./components/AppHeader.vue";

import { defineComponent } from "vue";

export default defineComponent({
  name: "App",

  components: {
    AppFooter,
    AppHeader,
  },

  data() {
    return {
      showRoutes: [
        "Account Settings",
        "Home",
        "RoutineDetail",
        "Routines",
        "Tags",
        "Users",
        "Workspace Select",
        "Workspace Settings",
      ],
    };
  },

  computed: {
    version(): string | undefined {
      return import.meta.env.PACKAGE_VERSION;
    },
  },
});
</script>

<style lang="scss">
#app {
  font-family: "Roboto Slab", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: relative;
}

html,
body {
  padding: 0;
  margin: 0;
  min-height: 100%;
}

.page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1em;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
}
</style>
